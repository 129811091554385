<div class="notify">
  <div class="notify__header">
    <h2>Notify</h2>
    <div>
      <a href="">Mark as read</a>
      <a href="">Setting</a>
    </div>
  </div>
  <div class="notify__content">
    <div class="sms-new">
      New
    </div>
    <div class="notify__content-list">
      <div class="notify__content-item">
        <div class="icon-avt" style="width: 48px; height: 48px; background-color: red;">
          <img src="" class="w-100" alt="" />
        </div>
        <div class="content-comment">
          <p>
            <span class="name-cmt">
              ĐNA
            </span>
            đã bình luận ảnh trong
            <span class="title-cmt">
              Ảnh 1
            </span>
          </p>
        </div>
        <div class="img-coment">
          <img
            src="https://scontent.fsgn2-2.fna.fbcdn.net/v/t1.0-0/cp0/s75x225/91518717_1046245515759212_5607127663748579328_n.jpg?_nc_cat=105&_nc_sid=07e735&_nc_ohc=1nWUgLLbu4oAX8zVvlU&_nc_ht=scontent.fsgn2-2.fna&oh=eba0c8ae4364a7017baa29f533dd7ceb&oe=5EAF94E4"
            class="w-100"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <div class="notify__footer"></div>
</div>
