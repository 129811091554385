import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ThemeModule } from './@theme/theme.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [ThemeModule],

})
export class NgxAdminModule { }
