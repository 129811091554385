<ng-container *ngIf="authenticated">
  <div class="header-container">
    <div class="logo-container">
      <button
        nz-button
        nzType="dashed"
        (click)="collapseSidebar()"
        *ngIf="(sidebarState$ | async) === 'expanded'"
      >
        <i nz-icon nzType="menu-fold" nzTheme="outline"></i>
      </button>
      <button
        nz-button
        nzType="dashed"
        (click)="expandSidebar()"
        *ngIf="(sidebarState$ | async) === 'collapsed'"
      >
        <i nz-icon nzType="menu-unfold" nzTheme="outline"></i>
      </button>
    </div>
  </div>

  <div class="header-container">
    <div class="mx-1">
      <nz-select
        [ngModel]="themes[0].value"
        (ngModelChange)="changeTheme($event)"
      >
        <nz-option
          *ngFor="let theme of themes"
          [nzValue]="theme.value"
          [nzLabel]="theme.name"
        ></nz-option>
      </nz-select>
    </div>

    <div class="mx-1">
      <nz-button-group>
        <button
          nz-button
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="language"
          nzTrigger="click"
          nzPlacement="bottomLeft"
        >
          <i nz-icon nzType="translation" nzTheme="outline"></i>
        </button>
      </nz-button-group>
      <nz-dropdown-menu #language="nzDropdownMenu">
        <ul nz-menu>
          <li
            *ngFor="let lang of translate.getLangs()"
            (click)="changeLanguage(lang)"
            nz-menu-item
          >
            <ng-container *ngIf="lang === 'vi'">Tiếng Việt</ng-container>
            <ng-container *ngIf="lang === 'en'">English</ng-container>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>

    <button
      nz-button
      nz-dropdown
      nzType="link"
      nzTrigger="click"
      [nzDropdownMenu]="userMenu"
      nzPlacement="bottomRight"
      nzTrigger="click"
      class="p-0"
    >
      <nz-avatar nzIcon="user" style="background-color: #009cdc"></nz-avatar>
    </button>
    <nz-dropdown-menu #userMenu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item>
          <a [routerLink]="['/', 'admin', 'profile']">
            <i nz-icon nzType="user" class="me-2"></i>
            <span>{{ 'Profile' | translate }}</span>
          </a>
        </li>
        <li nz-menu-item (click)="logout()">
          <i nz-icon nzType="logout" class="me-2"></i>
          <span>{{ 'Logout' | translate }}</span>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</ng-container>
