import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { environment } from '@frontend/env/environment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/components/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token =
      sessionStorage.getItem(environment.tokenKey) ??
      localStorage.getItem(environment.tokenKey);
    const redirectToLoginPage = () => {
      this.router.navigate(['/']);
    };
    if (token === null) {
      redirectToLoginPage();
      return false;
    } else {
      const exp = localStorage.getItem('exp');
      if (new Date() < new Date(Number(exp) * 1000)) {
        const roles = localStorage.getItem('roles');
        if (roles == '3') {
          return true;
        } else {
          this.notificationService.error(
            this.translate.instant('error.title'),
            this.translate.instant(
              'Hết phiên đăng nhập, vui lòng đăng nhập lại'
            ),
            { nzDuration: 3000 }
          );
          localStorage.clear();
          redirectToLoginPage();
          return false;
        }
      } else {
        this.notificationService.error(
          this.translate.instant('error.title'),
          this.translate.instant(
            'Hệ thống đã xảy ra lỗi, vui lòng thử lại sau'
          ),
          { nzDuration: 3000 }
        );
        localStorage.removeItem(environment.tokenKey);
        redirectToLoginPage();
        return false;
      }
    }
  }
}
