import { Component } from '@angular/core';

@Component({
  selector: 'app-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent {
  option: any;
  constructor() {
    this.option = this.getConfig();
  }

  getConfig() {
    const config = localStorage.getItem('config');
    return config !== null ? JSON.parse(config) : {};
  }
}
